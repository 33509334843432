import React, { useEffect } from "react";

const AboutCompany = () => {
  useEffect(() => {
    // IntersectionObserver logic
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
          observer.unobserve(entry.target); // Stop observing once it's visible
        }
      });
    });

    // Selecting all elements with the fade-in class
    const fadeElements = document.querySelectorAll('.fade-in');
    fadeElements.forEach((element) => observer.observe(element));

    // Clean up observer on unmount
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  <h2 className="tp-pt-size mb-40 fade-in">
                    Who are <br />Scorpion Capital?
                  </h2>
                  {/* <!-- breadcrumb area start 
                </div>
              
                  <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button>

                      <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vision
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Value
                      </button>
                    </div>
                    
                  </nav>
                  --> */}
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p className="fade-in">
                      We are a highly qualified and experienced team of financial and legal professionals with a proven record of originating debt and venture partners for government projects and the private sector.
                      <br/><br/> Whether you’re seeking funding for a new development, refinancing existing debt or exploring complex financial structures, our team will provide expert guidance and solutions tailored to your specific requirements. <br/><br/>
                        Our mission is to deliver tailored financial solutions that empower our clients to move forward by unlocking capital. We are driven by a commitment to excellence and a passion for solving financial challenges. Our experienced team leverages their deep industry knowledge to craft tailored strategies, Our approach is rooted in building long-lasting partnerships with our clients becoming their trusted partners as they grow.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p className="fade-in">
                        Our vision is to become the trusted advisor of choice in the financial industry, recognized for our ability to deliver innovative, effective, and personalized financial solutions. <br/><br/>We aspire to set new standards in how debt advisory services are delivered, placing our clients’ best interests at the core of every decision we make. Through our expansive network of capital providers—ranging from local and international banks to private lenders, institutional investors, and family offices—we seek to continually expand our reach and influence, enabling us to meet the evolving needs of our clients across diverse markets. Our goal is to foster financial growth and stability for every client, ensuring sustainable success in a rapidly changing world.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p className="fade-in">
                        Our values are built on integrity, expertise, and client-centricity. We believe in providing transparent, ethical, and highly personalized services that reflect our commitment to delivering real value. <br/><br/>Our approach is collaborative, ensuring that we understand and align with the unique aspirations of each client. By combining our in-depth industry experience with strategic partnerships, we deliver results that consistently exceed expectations. We hold ourselves accountable to the highest professional standards, making sure that every solution we provide is not only effective but also tailored to our clients’ long-term financial well-being. Our values guide us in everything we do, driving our pursuit of excellence and our dedication to helping clients succeed.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-about-img-box p-relative d-inline-block mb-30 text-center">
                <div className="about-page-img fade-in">
                  <img src="assets/img/about/about-page.png" alt="a business meeting featuring four people, two are shaking hands, there is a scorpion painting on the wall." />
                </div>
                <div className="dots-img fade-in">
                  <img src="assets/img/about/dot.jpg" alt="dots laid out over a transparent screen" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutCompany;
